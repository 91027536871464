import {Injectable} from '@angular/core';

import {IUser, IUserAuthReq, IUserForgotPasswordReq, IUserForgotPasswordSetNewPasswordReq} from '@models/user-auth';

import {Auth} from 'aws-amplify';

import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private _user: IUser | undefined;
  private _userEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private _signOutEvent$: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  public setUser(user: IUser): void {
    this._user = user;
    this._userEvent$.next(user);
  }

  public getUser(): Observable<IUser> {
    return this._userEvent$.asObservable()
      .pipe(filter((v) => v != null));
  }

  public getUserSync(): IUser | undefined {
    return this._user;
  }

  public signIn(signInReq: IUserAuthReq): Observable<IUser> {
    // MetaData Key for Cognito response-message-lambda
    // const LANGUAGE_META_DATA_KEY = 'language';
    // const REDIRECT_PATH_META_DATA_KEY = 'redirectPath';
    // const clientMetadata = {
    //   [LANGUAGE_META_DATA_KEY]: signInReq.language,
    //   [REDIRECT_PATH_META_DATA_KEY]: signInReq.confirmPasswordResetRedirectPath,
    // };

    return from(Auth.signIn({ ...signInReq, }, ''));
  }

  public signOut(): Observable<void> {
    this._signOutEvent$.next(true);
    return from(Auth.signOut({global: true}));
  }

  public signOutState(): Observable<boolean> {
    return this._signOutEvent$.asObservable();
  }

  public fetchUserInfo(): Observable<IUser> {
    return from(Auth.currentUserInfo());
  }

  public async getAccessToken(): Promise<string> {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return accessToken;
  }

  public changePassword(user: IUser, newPassword: string): any {
    return Auth.completeNewPassword(user, newPassword);
  }

  public forgotPassword(req: IUserForgotPasswordReq): Observable<any> {
    // MetaData Key for Cognito response-message-lambda
    const LANGUAGE_META_DATA_KEY = 'language';
    const REDIRECT_PATH_META_DATA_KEY = 'redirectPath';
    const clientMetadata = {
      [LANGUAGE_META_DATA_KEY]: req.language,
      [REDIRECT_PATH_META_DATA_KEY]: req.confirmPasswordResetRedirectPath,
    };
    return from(Auth.forgotPassword(req.username, clientMetadata));
  }

  public forgotPasswordSetNewPassword(req: IUserForgotPasswordSetNewPasswordReq): Observable<any> {
    return from(Auth.forgotPasswordSubmit(req.username, req.token, req.password));
  }
}
