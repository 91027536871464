import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {UserAuthService} from '@services/index';
import {EUserRole} from '@models/user-auth';

import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Auth} from "aws-amplify";

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _userAuthService: UserAuthService
    ) { }

    // @ts-ignore
  async canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): Promise<boolean> {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      try {
        user.refreshSession(session.getRefreshToken(), (err: any, sess: any) => {
          console.log('session', err, sess);
          const {idToken, refreshToken, accessToken} = sess;

          if (!accessToken) {
            return this._userAuthService.signOut()
              .pipe(
                map(() => {
                  this._router.navigate(['/auth/login']);
                  return false;
                }),
                catchError(() => {
                  this._router.navigate(['/auth/login']);
                  return throwError(false);
                }),
              );
          }

          return of(!!accessToken);
        });
      } catch (e) {
        this._router.navigate(['/auth/login']);
        return false;
      }
    }
}

