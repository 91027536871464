import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';

import {from, mergeMap, Observable, scan, toArray} from 'rxjs';

import { environment } from '@env';

import { UserAuthService } from '@services/index';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptor implements HttpInterceptor {
  private _baseUrl = environment.apiUrl;

  constructor(
    private _userAuthService: UserAuthService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isRelativePath = req.url.includes('/assets') || req.url.startsWith('http://') || req.url.startsWith('https://');
    if (isRelativePath) {
      return next.handle(req);
    }
    return from(this._userAuthService.getAccessToken())
      .pipe(
        toArray(),
        mergeMap((accessToken) => {
          let params: HttpParams = (req.params) ? req.params : new HttpParams();
          let headers: HttpHeaders = (req.headers) ? req.headers : new HttpHeaders();
          const url: string = `${this._baseUrl}${req.url}`;

          if (!params.has('authorization') || (params.has('authorization') && params.get('authorization') === 'true')) {
            if (accessToken) {
              headers = headers.append('Authorization', `Bearer ${accessToken}`);
            }
          }
          if (params.has('authorization')) {
            params = params.delete('authorization');
          }
          const clonedReq = req.clone({
            url,
            headers,
            params
          });
          return next.handle(clonedReq);
        })
      );
  }
}
