import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { UserAuthService } from '@services/index';
import { IUser} from '@models/user-auth';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _userAuthService: UserAuthService
  ) {}

  canActivate(): Observable<boolean> {
    return this._userAuthService.fetchUserInfo()
      .pipe(
        map((user: IUser) => {
          if (user && Object.keys(user).length > 0) {
            this._userAuthService.setUser(user);
            return true;
          }
          this._router.navigate(['/auth/login']);
          return false;
        }),
        catchError((_) => {
          this._router.navigate(['/auth/login']);
          return throwError(false);
        })
      );
  }
}

